<template>
  <div :class="`${prefixCls}-product`" id="ProductCenter">
    <Separate type="light" title="产品中心" desc="PRODUCT CENTER" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div
        :class="`${prefixCls}-product-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
        <el-breadcrumb separator="/" v-if="!validatenull(detailData)">
            <el-breadcrumb-item :to="{path: '/core', query: { id: undefined
            }}">产品中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{ detailData.commonName }}</el-breadcrumb-item>
        </el-breadcrumb>
<!--      <div class="product-type-nav">-->
<!--        <div class="product-type" v-for="item in typeNavList" :key="item.id" :class="{ active: active === item.id }"-->
<!--             @click="handleNavClick(item.id)">-->
<!--          <div class="icon-layout">-->
<!--            <em class="iconfont icon" :class="item.icon" />-->
<!--          </div>-->
<!--          <div class="label">{{item.name}}</div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="product-content" v-show="!isDetail">
          <div class="product" v-for="item in productList" :key="item.id" @click="handleClick(item)">
              <div class="desc-layout">
                <span class="title">{{ item.commonName }}</span>
<!--                  <span class="title">{{ item.name }}</span>-->
<!--                  <div class="desc">-->
<!--                      <span>规格：{{ item.gz }}</span>-->
<!--                      <span>包装：{{ item.bz }}</span>-->
<!--                      <span>适应症：{{ item.syz }}</span>-->
<!--                  </div>-->
                  <div class="btn-group">
                      <button class="detail">查看详情</button>
                  </div>
              </div>
              <img class="thumbnail" :src="item.pic" alt="">
              <img :src="item.pic" alt="" class="origin">
          </div>
<!--        <el-carousel ref="carousel" class="code-product-carousel" :autoplay="false" @change="handleChange">-->
<!--          <el-carousel-item v-for="(item, index) in list" :key="index">-->
<!--            <div class="product-card" v-for="element in item" :key="element.id" @click="handleClick(element)">-->
<!--              <div class="img-box">-->
<!--                <img :src="element.pic" alt="">-->
<!--              </div>-->
<!--              <div class="label">{{ element.name }}</div>-->
<!--            </div>-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
      </div>
      <div class="detail-box" v-show="isDetail">
        <div class="img-box">
<!--          <img :src="detailData.pic" alt="" v-if="detailData.images.length !== 0">-->
          <el-carousel ref="carousel" height="432px" autoplay>
            <el-carousel-item v-for="(item, index) in detailData.images" :key="index">
              <img class="carousel-img" :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
          <div class="thumbnail-box">
            <img v-for="(item, index) in detailData.images" :src="item" :key="index" alt="" @mouseenter="handleImageChange(index)">
          </div>
        </div>
        <div class="detail-form">
            <div class="label" style="margin-bottom: 10px;text-align: left; transform: translateX(-10px); font-size: 18px; font-weight: bold;">【药品名称】</div>
            <div class="detail-form-item">
              <span class="label">{{ `通用名称：` }}</span>
              <span class="value">{{ getValue({ prop: 'commonName' }) }}</span>
            </div>
          <div v-if="getValue({prop: 'name'})" class="detail-form-item">
            <span class="label">{{ `商品名称：` }}</span>
            <span class="value">{{ getValue({prop: 'name'}) }}</span>
          </div>
          <div v-if="getValue({prop: 'oldName'})" class="detail-form-item">
            <span class="label">{{ `曾用名：` }}</span>
            <span class="value">{{ getValue({prop: 'oldName'}) }}</span>
          </div>
          <div class="label" style="margin-bottom: 10px;text-align: left; transform: translateX(-10px); font-size: 18px; font-weight: bold;">【适应症】</div>
          <div class="detail-form-item">
<!--            <span class="label">{{ `曾用名：` }}</span>-->
            <span class="value">{{ getValue({prop: 'syz'}) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '@/components/Separate/index'
import { getList, siteGoodPage, siteGoodDetail } from '@/api/index'
import { validatenull } from '@/utils/validate'
import merge from 'webpack-merge'
export default {
  name: 'ProductCenter',
  components: { Separate },
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      active: 0,
      typeNavList: [],
      productList: [],
      detailData: {
      },
      formConfig: [
        // {
        //   label: '药品名称',
        //   prop: 'name'
        // },
        {
          label: '通用名称',
          prop: 'commonName'
        },
        {
          label: '商品名称',
          prop: 'name'
        },
        {
          label: '曾用名',
          prop: 'oldName'
        }, {
          label: '适应症',
          prop: 'syz'
        }
        // {
        //   label: '其他信息',
        //   prop: 'other'
        // }
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val.query.id) {
          this.handleClick({ id: this.$route.query.id })
        } else {
          this.detailData = {}
        }
      }
    }
  },
  computed: {
    list () {
      const data = []
      const list = this.productList.filter(
        (item) => item.cateId === this.active
      )
      for (let i = 0; i < list.length; i += 4) {
        data.push(list.slice(i, i + 4))
      }
      return data
    },
    isDetail () {
      return !validatenull(this.detailData)
    }
  },
  created () {
    // this.getList()
    this.siteGoodPage()
    if (!validatenull(this.$route.query.id)) {
      this.siteGoodDetail(this.$route.query.id)
    }
  },
  methods: {
    handleImageChange (index) {
      this.$refs.carousel.setActiveItem(index)
    },
    validatenull,
    getValue (data) {
      const { prop, formatter } = data
      if (prop === 'oldName' && validatenull(this.detailData[prop])) {
        return false
      }
      if (prop === 'name' && validatenull(this.detailData[prop])) {
        return false
      }
      if (!validatenull(formatter)) {
        return formatter(prop)
      }
      if (validatenull(this.detailData[prop])) return '--'
      return this.detailData[prop]
    },
    getList () {
      getList().then(({ data }) => {
        this.typeNavList = data.data
        if (!this.$route.query.searchType) {
          this.active = data.data[0].id
        }
      })
    },
    siteGoodPage () {
      siteGoodPage().then(({ data }) => {
        this.productList = data.data.rows
        this.sortData(this.productList)
      })
    },
    siteGoodDetail (id) {
      siteGoodDetail(id).then(({ data }) => {
        this.detailData = {
          ...data.data,
          bz: data.data.bz.split(',')
        }
      })
    },
    handleNavClick (id) {
      this.active = id
      this.detailData = {}
      this.$router.push({
        query: merge(this.$route.query, { searchType: id, id: undefined })
      })
    },
    handleChange (index) {
      this.active = index
    },
    handleClick (data) {
      this.$router.push({
        query: merge(this.$route.query, { id: data.id })
      })
      this.siteGoodDetail(data.id)
    },
    sortData (arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        // 内层循环,控制比较的次数，并且判断两个数的大小
        for (let j = 0; j < arr.length - 1 - i; j++) {
          // 白话解释：如果前面的数大，放到后面(当然是从小到大的冒泡排序)
          if (arr[j].sort > arr[j + 1].sort) {
            const temp = arr[j]
            arr[j] = arr[j + 1]
            arr[j + 1] = temp
          }
        }
      }
    },
    test () {
      console.log(111)
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-product;
.#{$prefixCls}{
  width: 100%;
  //background-image: url("~@/assets/image/home/productCenterBg.png");
  //background-repeat: no-repeat;
  //background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px;
  overflow: hidden;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: calc(100% - 50px);
    //height: 800px;
    overflow: hidden;
    .product-type-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      cursor: pointer;
      .product-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-layout {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 66px;
          height: 66px;
          border: 1px solid #fff;
          border-radius: 50%;
          margin-bottom: 10px;
          .icon {
            color: rgb(0, 49, 132);
            font-size: 36px;
          }
        }
        &.active {
          .icon-layout {
            border: 1px solid rgb(0, 49, 132);
            background-color: #fff;
          }
        }
        &:hover {
          .icon-layout {
            background-color: #fff;
            color: rgb(0, 49, 132);
          }
        }
        .label {
          width: 5em;
          font-size: 18px;
        }
      }
    }
    .product-content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: $content-width;
      //flex: 1;
      //overflow: auto;
      background-color: #fff;
      margin-top: 20px;
      .product {
        position: relative;
        display: flex;
        align-items: center;
        width: 550px;
        height: 250px;
        border: 1px solid #D8D8D8;
        margin: 0 40px 40px 0;
        padding: 15px;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          .origin {
            right: 0px;
          }
        }
        .desc-layout {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          height: 100%;
          margin-right: 10px;
          .title {
            font-size: 20px;
            color: #000;
            line-height: 40px;
            margin-bottom: 40px;
          }
          .desc {
            width: 100%;
            display: flex;
            flex-direction: column;
            span {
              width: 100%;
              font-size: 14px;
              text-align: left;
              margin-bottom: 10px;
              overflow:hidden;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
            }
          }
          .btn-group {
            //flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            .detail {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 92px;
              height: 30px;
              border-radius: 15px;
              background-color: #fff;
              border: 1px solid #D8D8D8;
              color: #575757;
              margin-bottom: 15px;
              cursor: pointer;
            }
          }
        }
        .thumbnail {
          width: 230px;
          height: 200px;
          object-fit: contain;
        }
        .origin {
          position: absolute;
          width: 100%;
          height: 100%;
          right: -550px;
          transition: all .3s;
        }
      }
    }
    .detail-box {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 540px;
      margin-top: 50px;
      .img-box {
        width: 640px;
        height: 432px;
        border: 1px solid rgb(212, 212, 212);
        margin-right: 50px;
        .carousel-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .thumbnail-box {
          display: flex;
          align-items: center;
          margin-top: 20px;
          img {
            width: 120px;
            height: 80px;
            object-fit: cover;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .detail-form {
        pointer-events: none;
        .title {
          font-size: 26px;
          color: #0d319f;
          margin-bottom: 15px;
        }
        &-item {
          display: flex;
          font-size: 18px;
          margin-bottom: 30px;
          .label {
            width: 7em;
            text-align: left;
            font-family: arial !important;
            font-size: 16px !important;
            color: #191818 !important;
            line-height: 40px !important;
            font-weight: lighter !important;
          }
          .value {
            width: 26em;
            text-align: left;
            font-family: arial !important;
            font-size: 16px !important;
            color: #191818 !important;
            line-height: 40px !important;
            font-weight: lighter !important;
            .value-item {
              display: flex;
              align-items: center;
              width: 100%;
              height: 35px;
              .index {
                width: 2em;
              }
            }
          }
        }
      }
    }
  }
}
</style>
