<template>
  <div :class="prefixCls" id="productCore">
    <div :class="`${prefixCls}-header`">
      <components-carousel-index :carouselData="swipeList" />
    </div>
    <product-center />
    <!--    <Chart id="coreChart"></Chart>-->
    <!--    <BigData id="coreBigData"></BigData>-->
    <!--    <Superiority id="coreSuperiority"></Superiority>-->
  </div>
</template>

<script>
// import Chart from './components/chart.vue'
// import BigData from './components/bigData.vue'
// import Superiority from './components/superiority.vue'
import ProductCenter from '../core/components/ProductCenter.vue'
import { getBannerByChannelId } from '@/api'
import ComponentsCarouselIndex from '@/components/Carousel/index.vue'
export default {
  name: 'Core',
  components: {
    ComponentsCarouselIndex,
    ProductCenter
    // Chart,
    // BigData,
    // Superiority
  },
  data () {
    return {
      prefixCls: this.$prefix + '-core',
      caseData: {},
      swipeList: []
    }
  },
  created () {
    this.getBannerByChannelId()
  },
  methods: {
    getBannerByChannelId () {
      getBannerByChannelId(3).then(({ data }) => {
        this.swipeList = data.data
        console.log(this.swipeList, 'swipeListswipeList')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -core;
.#{$prefixCls}{
  width: 100%;
  flex-grow: 1;
  &-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    //&-img{
    //  width: 100%;
    //  min-width: $content-width;
    //  height: 600px;
    //  object-fit: cover;
    //}
  }
}
</style>
